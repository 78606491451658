/* eslint-disable max-len */

// components
import GoogleMaps from '@/js/components/google_maps/map';
import ProjectManagementForm from '@/js/components/forms/project-management-form';
import {
  deleteProjectPhoto, setProjectFilesOrder, setProjectStatus, deactivateProject,
} from '@/js/api/api';
import Sortable from 'sortablejs';
import Dropzone from 'dropzone';
import { rootWithLanguage } from '@/js/utils/paths';

class ProjectManagementDetail {
  constructor() {
    this.dom = {
      mapEl: document.getElementById('map-picker'),
      formProject: document.getElementById('form-project'),

      pdfUpload: document.getElementById('project-pdf'),
      pdfUploadReceiver: document.querySelector('.pdf-label'),

      surroundingsUpload: document.getElementById('surroundings-photo'),
      surroundingsUploadReceiver: document.querySelector('.sur-label'),

      dropzoneEl: document.getElementById('my-dropzone'),
      langSelect: document.getElementById('project-lang'),

      statusSwitch: document.getElementById('status-switch'),

      alertContainer: document.getElementById('alert-container'),
    };

    this.props = {
      infoWindowContent: null,
    };

    this.defaultMapOptions = {
      center: { lat: 50.91263082619794, lng: 3.647702889873862 },
      zoom: 11, // You can adjust the default zoom level
    };

    this.data = {
      mapCoordinates: undefined,
      projectId: document.querySelector('[name="ProjectID"]').value,

    };

    this.components = {
      googleMaps: null,
      projectManagementForm: null,
    };

    this.events = {
      // onMapLoaded: this.onMapLoaded.bind(this),
    };

    this.dropzoneInstance = null;
    this.sortableInstance = null;

    this.mount();
  }

  mount() {
    if (this.dom.mapEl) {
      this.initMaps();
    }

    if (this.dom.formProject) {
      this.components.projectManagementForm = new ProjectManagementForm((response) => this.handleResponseMessage(response));
    }

    if ((this.dom.pdfUpload && this.dom.pdfUploadReceiver)) {
      this.pdfUpload();
    }

    if ((this.dom.surroundingsUpload && this.dom.surroundingsUploadReceiver)) {
      this.SurroundingsUpload();
    }

    if (this.dom.dropzoneEl) {
      this.createDropzone();
    }

    if (this.dom.langSelect) {
      this.dom.langSelect.addEventListener('change', () => {
        this.langSelector();
      });
    }

    if (this.dom.statusSwitch) {
      this.dom.statusSwitch.addEventListener('change', () => this.toggleProjectStatus(this.dom.statusSwitch));
    }
  }

  initMaps() {
    // Check if data-latitude and data-longitude exist and are valid
    const latitude = parseFloat(this.dom.mapEl.getAttribute('data-latitude'));
    const longitude = parseFloat(this.dom.mapEl.getAttribute('data-longitude'));

    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(latitude) && !isNaN(longitude)) {
      this.defaultMapOptions.center = { lat: latitude, lng: longitude };
    }

    // Create map instance with the computed map options
    this.components.googleMaps = new GoogleMaps(this.dom.mapEl, this.defaultMapOptions);
  }

  langSelector() {
    const selectedLanguage = this.dom.langSelect.value;
    const inputFields = document.querySelectorAll('.field-ne, .field-fr, .field-en');

    inputFields.forEach((langGroup) => {
      if (langGroup.classList.contains(`field-${selectedLanguage}`)) {
        langGroup.classList.remove('visually-hidden');
      } else {
        langGroup.classList.add('visually-hidden');
      }
    });
  }

  pdfUpload() {
    this.dom.pdfUpload.addEventListener('change', () => {
      const fileName = this.dom.pdfUpload.value.split('\\').pop(); // Extract the file name from the file path
      this.dom.pdfUploadReceiver.textContent = fileName || ''; // Set the text of the label to the file name
    });
  }

  SurroundingsUpload() {
    this.dom.surroundingsUpload.addEventListener('change', () => {
      const fileName = this.dom.surroundingsUpload.value.split('\\').pop(); // Extract the file name from the file path
      this.dom.surroundingsUploadReceiver.textContent = fileName || ''; // Set the text of the label to the file name
    });
  }

  /**
   * dropzone filed has been removed
   * @param file
   * @returns {Promise<*|void>}
   */
  async dzRemovedFile(file) {
    if (file.previewElement.dataset.fileId) {
      try {
        // set file loading state
        file.previewElement.classList.add('dz-loading');

        // remove file
        const files = await deleteProjectPhoto({
          projectId: String(this.data.projectId),
          photoId: String(file.previewElement.dataset.fileId),
        });

        this.dzSetMaxFiles({ files });
        this.dzRemoveFileFromDOM(file);
      } catch (error) {
        // failed to remove file, allow file to be removed
        file.previewElement.classList.remove('dz-loading');
        throw new Error(error);
      }
    } else {
      this.dzRemoveFileFromDOM(file);
    }

    // eslint-disable-next-line no-underscore-dangle
    return this.dropzoneInstance._updateMaxFilesReachedClass();
  }

  // eslint-disable-next-line class-methods-use-this
  dzRemoveFileFromDOM(file) {
    if (file.previewElement.parentNode != null) {
      file.previewElement.parentNode.removeChild(file.previewElement);
    }
  }

  /**
   * add dropzone preview file
   * @param file
   * @param dropzone {Dropzone}
   */
  dzAddMockFile({ file, dropzone = this.dropzoneInstance }) {
    const mockFile = { name: file.name, size: file.size, id: file.id };
    dropzone.displayExistingFile(mockFile, file.url);

    // Add data-file-id attribute to the preview element
    if (mockFile.previewElement) {
      mockFile.previewElement.setAttribute('data-file-id', file.id);
    }
  }

  dzSetMaxFiles({ files, dropzone = this.dropzoneInstance }) {
    // eslint-disable-next-line no-param-reassign
    dropzone.options.maxFiles = Math.max(0, dropzone.options.maxFiles - files.length);
  }

  createDropzone() {
    const thisClass = this;

    this.dropzoneInstance = new Dropzone(this.dom.dropzoneEl, {
      url: `${rootWithLanguage}upload-project-photo`,
      paramName: 'file',
      maxFilesize: 10, // MB
      maxFiles: 20,
      acceptedFiles: '.jpg,.png,.jpeg',
      addRemoveLinks: true,
      thumbnailWidth: 60,
      thumbnailHeight: 60,
      previewsContainer: document.getElementById('template-preview'),
      previewTemplate:
        `<div class="dz-preview dz-file-preview">
          <div class="dz-image"><img data-dz-thumbnail /></div>
          <div class="dz-details">
            <div class="dz-size"><span data-dz-size></span></div>
            <div class="dz-filename"><span data-dz-name></span></div>
          </div>
          <div class="dz-progress">
            <span class="dz-upload" data-dz-uploadprogress></span>
          </div>
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
          <div class="dz-success-mark">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2071 29.7929L14.2929 25.7071C14.6834 25.3166 15.3166 25.3166 15.7071 25.7071L21.2929 31.2929C21.6834 31.6834 22.3166 31.6834 22.7071 31.2929L38.2929 15.7071C38.6834 15.3166 39.3166 15.3166 39.7071 15.7071L43.7929 19.7929C44.1834 20.1834 44.1834 20.8166 43.7929 21.2071L22.7071 42.2929C22.3166 42.6834 21.6834 42.6834 21.2929 42.2929L10.2071 31.2071C9.81658 30.8166 9.81658 30.1834 10.2071 29.7929Z" />
            </svg>
          </div>
          <div class="dz-error-mark">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.2929 20.2929L19.2071 13.2071C18.8166 12.8166 18.1834 12.8166 17.7929 13.2071L13.2071 17.7929C12.8166 18.1834 12.8166 18.8166 13.2071 19.2071L20.2929 26.2929C20.6834 26.6834 20.6834 27.3166 20.2929 27.7071L13.2071 34.7929C12.8166 35.1834 12.8166 35.8166 13.2071 36.2071L17.7929 40.7929C18.1834 41.1834 18.8166 41.1834 19.2071 40.7929L26.2929 33.7071C26.6834 33.3166 27.3166 33.3166 27.7071 33.7071L34.7929 40.7929C35.1834 41.1834 35.8166 41.1834 36.2071 40.7929L40.7929 36.2071C41.1834 35.8166 41.1834 35.1834 40.7929 34.7929L33.7071 27.7071C33.3166 27.3166 33.3166 26.6834 33.7071 26.2929L40.7929 19.2071C41.1834 18.8166 41.1834 18.1834 40.7929 17.7929L36.2071 13.2071C35.8166 12.8166 35.1834 12.8166 34.7929 13.2071L27.7071 20.2929C27.3166 20.6834 26.6834 20.6834 26.2929 20.2929Z" />
            </svg>
          </div>
          <div class="dz-spinner"></div>
        </div>`,
      dictRemoveFile: '',
      dictCancelUpload: '',
      parallelUploads: 1, // do not change, keeps correct order on page reload
      init() {
        const existingFiles = JSON.parse(document.getElementById('existing-files-data').dataset.existingFiles);
        existingFiles.forEach((existingFile) => thisClass.dzAddMockFile({ dropzone: this, file: existingFile }));
        thisClass.dzSetMaxFiles({ files: existingFiles, dropzone: this });

        thisClass.createSortable();
      },
      success(file, response) {
        response.forEach((responseObj) => {
          const fileId = responseObj.id;
          const { previewElement } = file;
          previewElement.setAttribute('data-file-id', fileId);
        });
      },
      removedfile: (file) => this.dzRemovedFile(file),
      sending: (file, xhr, formData) => {
        formData.append('ProjectID', this.data.projectId);
      },
    });
  }

  createSortable() {
    const thisClass = this;
    const previewsContainer = document.getElementById('template-preview');
    this.sortableInstance = new Sortable(previewsContainer, {
      animation: 150,
      async onEnd() {
        const newOrder = [...previewsContainer.children].map((preview) => preview.getAttribute('data-file-id'));

        try {
          await setProjectFilesOrder({
            newOrder,
            projectId: String(thisClass.data.projectId),
          });
        } catch (error) {
          throw new Error(error);
        }
      },
    });
  }

  toggleProjectStatus(statusSwitch) {
    const { projectId } = this.data;
    const newStatus = statusSwitch.checked;

    const statusChangeFunction = newStatus ? setProjectStatus : deactivateProject;

    statusChangeFunction({ projectId })
      .then((response) => {
        if (response.data.status === 'error') {
          this.displayAlert('alert-warning', response.data.message);
          // eslint-disable-next-line no-param-reassign
          statusSwitch.checked = !newStatus;
        } else if (response.data.status === 'success') {
          // eslint-disable-next-line no-param-reassign
          statusSwitch.checked = newStatus;
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        // eslint-disable-next-line no-param-reassign
        if (error.response) {
          // Handle the response error if it exists
          this.displayAlert('alert-warning', 'API request failed. Switch state reverted.');
        }
      });
  }

  handleResponseMessage(response) {
    const { message, errors } = response.data;

    const addErrorDisplayClass = (fieldNames) => {
      if (fieldNames) {
        fieldNames.forEach((fieldName) => {
          const inputField = document.querySelector(`[name="${fieldName}"]`);
          if (inputField) {
            inputField.classList.add('error-display');

            // Replace the placeholder with the error message
            const errorMessage = errors[fieldName];
            if (errorMessage) {
              inputField.setAttribute('placeholder', errorMessage);
            }
          }
        });
      }
    };

    if (message.type === 'success') {
      this.displayAlert('alert-success', message.text);
    } else if (message.type === 'warning') {
      this.displayAlert('alert-warning', message.text);
      this.dom.statusSwitch.checked = false;
      addErrorDisplayClass(Object.keys(errors));
    } else {
      this.displayAlert('alert-danger', `Unknown message type: ${message.type}`);
      this.dom.statusSwitch.checked = false;
      addErrorDisplayClass(Object.keys(errors));
    }
  }

  displayAlert(alertClass, message) {
    const alertElement = document.createElement('div');
    alertElement.classList.add('alert', alertClass, 'alert-dismissible', 'fade', 'show');
    alertElement.innerHTML = `${message}`;
    this.dom.alertContainer.appendChild(alertElement);

    // Automatically close the alert after a few seconds
    setTimeout(() => {
      alertElement.classList.remove('show');
      alertElement.classList.add('hide');
      setTimeout(() => {
        this.dom.alertContainer.removeChild(alertElement);
      }, 500);
    }, 5000);
  }
}

if (document.getElementById('page-project-management-detail')) {
  // eslint-disable-next-line no-new
  new ProjectManagementDetail();
}
