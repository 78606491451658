import FilterProjects from '../components/forms/filter_projects';
import { submitProjectFilters } from '../api/api';

if (document.getElementById('page-project-overview')) {
  const filterProjects = document.querySelector('.js-projects-form');
  // eslint-disable-next-line no-unused-vars
  const selectSorting = document.getElementById('sort');
  let filterProjectsComponent;

  const sortSelectChange = () => {
    filterProjectsComponent.submitForm();
  };

  if (filterProjects) {
    filterProjectsComponent = new FilterProjects({
      form: filterProjects,
      fnApi: submitProjectFilters,
      selectSorting,
    });

    if (selectSorting) {
      selectSorting.addEventListener('change', sortSelectChange);
    }

    filterProjects.addEventListener('submit', (e) => {
      e.preventDefault();
      filterProjectsComponent.submitForm();
    });
  }
}
