// eslint-disable-next-line import/no-extraneous-dependencies
import baguetteBox from 'baguettebox.js';
import Swiper, {
  Navigation, Pagination, EffectFade, Lazy, Autoplay,
} from 'swiper/core';

// components
import Validation from '@/js/components/forms/validation';
import GoogleMaps from '@/js/components/google_maps/map';

Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay]);

class ProjectDetails {
  constructor() {
    this.dom = {
      mediaButton: document.querySelector('.media-btn'),
      mediaIndex: 0,
      mobileSwiper: document.querySelector('.swiper'),
      mapEl: document.getElementById('project-map'),
      formProject: document.querySelector('.form-manager-page'),
      formModal: document.querySelectorAll('.form-manager-modal'),
      descriptionShown: document.getElementById('projectDescription'),
      descriptionHidden: document.getElementById('description-collapse'),
      descriptionLimit: 100,
    };

    this.props = {
      infoWindowContent: null,
    };

    this.components = {
      googleMaps: null,
      ManagerFormValidation: null,
    };

    this.events = {
      onMapLoaded: this.onMapLoaded.bind(this),
    };

    this.data = {
      swiperConfig: {
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
      },
    };
    this.mount();
  }

  // eslint-disable-next-line class-methods-use-this
  mount() {
    baguetteBox.run('.gallery', {
      animation: 'slideIn',
      noScrollbars: true,
    });

    if (this.dom.mediaButton) {
      this.dom.mediaButton.addEventListener('click', () => this.showGalleryAtIndex());
    }

    if (this.dom.mobileSwiper) {
      this.dom.mobileSwiper = new Swiper(this.dom.mobileSwiper, this.data.swiperConfig);
    }

    if (this.dom.mapEl) {
      this.initMaps();
    }

    if (this.dom.formProject) {
      // eslint-disable-next-line no-new
      new Validation(this.dom.formProject);
    }
    this.dom.formModal.forEach((modal) => {
      if (modal) {
        // eslint-disable-next-line no-new
        new Validation(modal);
      }
    });

    // description
    const projectDescription = this.dom.descriptionShown.innerHTML;
    const descriptionWords = projectDescription.split(' ');

    if (descriptionWords.length > this.dom.descriptionLimit) {
      const part1 = descriptionWords.slice(0, this.dom.descriptionLimit).join(' ');
      const part2 = descriptionWords.slice(this.dom.descriptionLimit).join(' ');
      this.dom.descriptionShown.innerHTML = part1;
      this.dom.descriptionHidden.innerHTML = part2;
    }
  }

  showGalleryAtIndex() {
    const gallery = baguetteBox.run('.gallery', {
      animation: 'slideIn',
      noScrollbars: true,
    });
    baguetteBox.show(
      this.dom.mediaIndex,
      gallery[0],
    );
  }

  initMaps() {
    // Define the map options directly in the code
    this.props.mapOptions = {
      center: { lat: 50.91263082619794, lng: 3.647702889873862 },
    };

    // Use the data-latitude and data-longitude attributes to set the map's center
    const latitude = parseFloat(this.dom.mapEl.getAttribute('data-latitude'));
    const longitude = parseFloat(this.dom.mapEl.getAttribute('data-longitude'));

    // Set the center property of mapOptions
    this.props.mapOptions.center = { lat: latitude, lng: longitude };

    // listen to map loaded
    this.dom.mapEl.addEventListener('loaded', this.events.onMapLoaded);

    // create map instance
    this.components.googleMaps = new GoogleMaps(this.dom.mapEl, this.props.mapOptions);
  }

  onMapLoaded() {
    // Add a marker at the map's center coordinates
    const centerMarker = {
      position: this.props.mapOptions.center, // Use the center coordinates from mapOptions
    };

    this.components.googleMaps.addMarker(centerMarker);
  }
}

if (document.getElementById('page-project-detail')) {
  // eslint-disable-next-line no-new
  new ProjectDetails();
}
