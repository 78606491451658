import ActionHandler from '@/js/components/forms/action_handler';
import { rootWithLanguage } from '@/js/utils/paths';
import { setProjectStatus, deactivateProject } from '@/js/api/api';

class ProjectManagement extends ActionHandler {
  constructor() {
    super();
    document.getElementById('make-project').addEventListener('click', () => this.makeProject());
    this.deleteEventListener();
    // Add an event listener to all checkboxes with the class "form-check-input"
    const checkboxes = document.querySelectorAll('.form-check-input');
    if (checkboxes) {
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', () => this.toggleProjectStatus(checkbox));
      });
    }
  }

  makeProject() {
    const formData = new FormData();
    formData.append('action', 'MakeProject');
    this.send({ url: window.location.href, formData });
  }

  deleteEventListener() {
    const table = document.querySelector('.table-overview'); // Replace with your actual table selector
    table.addEventListener('click', (event) => {
      const deleteBtn = event.target.closest('.delete-project');
      if (!deleteBtn) return; // If the clicked element is not a delete button, do nothing
      event.preventDefault();
      const row = deleteBtn.closest('tr');
      const projectId = row.getAttribute('data-project-id');
      this.deleteProject(projectId);
    });
  }

  deleteProject(projectId) {
    const formData = new FormData();
    formData.append('ProjectID', projectId);
    this.send({ url: `${rootWithLanguage}delete-project`, formData });
  }

  toggleProjectStatus(checkbox) {
    const projectId = checkbox.closest('tr').getAttribute('data-project-id');
    const newStatus = checkbox.checked;

    const statusChangeFunction = newStatus ? setProjectStatus : deactivateProject;

    statusChangeFunction({ projectId })
      .then((response) => {
        if (response.data.status === 'error') {
          this.displayAlert('alert-warning', response.data.message);
          // eslint-disable-next-line no-param-reassign
          checkbox.checked = !newStatus;
        } else if (response.data.status === 'success') {
          // eslint-disable-next-line no-param-reassign
          checkbox.checked = newStatus;
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-param-reassign
        if (error.response) {
          // Handle the response error if it exists
          this.displayAlert('alert-warning', 'API request failed. Switch state reverted.');
        }
      });
  }

  // eslint-disable-next-line class-methods-use-this
  displayAlert(alertClass, message) {
    const alertContainer = document.getElementById('alert-container');
    const alertElement = document.createElement('div');
    alertElement.classList.add('alert', alertClass, 'alert-dismissible', 'fade', 'show');
    alertElement.innerHTML = `${message}`;
    alertContainer.appendChild(alertElement);

    // Automatically close the alert after a few seconds
    setTimeout(() => {
      alertElement.classList.remove('show');
      alertElement.classList.add('hide');
      setTimeout(() => {
        alertContainer.removeChild(alertElement);
      }, 500);
    }, 5000);
  }
}

if (document.getElementById('page-project-management')) {
  // eslint-disable-next-line no-new
  new ProjectManagement();
}
