import ActionHandler from '@/js/components/forms/action_handler';
import { setEstateStatus, deactivateEstate } from '@/js/api/api';

class EstateManagement extends ActionHandler {
  constructor() {
    super();
    document.getElementById('make-estate').addEventListener('click', () => this.makeEstate());
    this.deleteEventListener();

    const checkboxes = document.querySelectorAll('.form-check-input');
    if (checkboxes) {
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', () => this.toggleEstateStatus(checkbox));
      });
    }
  }

  makeEstate() {
    const formData = new FormData();
    formData.append('action', 'MakeEstate');
    formData.append('ProjectID', document.getElementById('make-estate').getAttribute('data-projectid'));
    this.send({ url: window.location.href, formData });
  }

  deleteEventListener() {
    const table = document.querySelector('.table-overview'); // Replace with your actual table selector
    table.addEventListener('click', (event) => {
      const deleteBtn = event.target.closest('.delete-estate');
      if (!deleteBtn) return; // If the clicked element is not a delete button, do nothing
      event.preventDefault();
      const row = deleteBtn.closest('tr');
      const projectId = row.getAttribute('data-project-id');
      const estateId = row.getAttribute('data-estate-id');
      const url = row.getAttribute('data-url');
      this.deleteEstate(projectId, estateId, url);
    });
  }

  deleteEstate(projectId, estateId, url) {
    const formData = new FormData();
    formData.append('action', 'DeleteEstate');
    formData.append('ProjectID', projectId);
    formData.append('EstateID', estateId);
    this.send({ url, formData });
  }

  toggleEstateStatus(checkbox) {
    const estateId = checkbox.closest('tr').getAttribute('data-estate-id');
    const newStatus = checkbox.checked;

    const statusChangeFunction = newStatus ? setEstateStatus : deactivateEstate;

    statusChangeFunction({ estateId })
      .then((response) => {
        if (response.data.status === 'error') {
          this.displayAlert('alert-warning', response.data.message);
          // eslint-disable-next-line no-param-reassign
          checkbox.checked = !newStatus;
        } else if (response.data.status === 'success') {
          // eslint-disable-next-line no-param-reassign
          checkbox.checked = newStatus;
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-param-reassign
        if (error.response) {
          // Handle the response error if it exists
          this.displayAlert('alert-warning', 'API request failed. Switch state reverted.');
        }
      });
  }

  // eslint-disable-next-line class-methods-use-this
  displayAlert(alertClass, message) {
    const alertContainer = document.getElementById('alert-container');
    const alertElement = document.createElement('div');
    alertElement.classList.add('alert', alertClass, 'alert-dismissible', 'fade', 'show');
    alertElement.innerHTML = `${message}`;
    alertContainer.appendChild(alertElement);

    // Automatically close the alert after a few seconds
    setTimeout(() => {
      alertElement.classList.remove('show');
      alertElement.classList.add('hide');
      setTimeout(() => {
        alertContainer.removeChild(alertElement);
      }, 500);
    }, 5000);
  }
}

if (document.getElementById('page-estate-management')) {
  // eslint-disable-next-line no-new
  new EstateManagement();
}
