import Swiper, {
  Navigation, Pagination, EffectFade, Lazy, Autoplay,
} from 'swiper/core';

import FilterProjects from '@/js/components/forms/filter_projects';
import { submitHomeFilters } from '../api/api';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay]);

if (document.getElementById('page-home')) {
  const filterForm = document.querySelector('.search__form');
  const checks = document.querySelectorAll('.housing-type > .form-check-input, .price-type > .form-check-input');
  let filterProjectsComponent;

  if (filterForm) {
    filterProjectsComponent = new FilterProjects({
      form: filterForm,
      fnApi: submitHomeFilters,
      autoSubmit: false,
    });

    filterForm.addEventListener('submit', (e) => {
      e.preventDefault();
      filterProjectsComponent.submitForm();
    });
  }

  checks.forEach((check) => {
    check.addEventListener('change', () => {
      const filterItem = check.closest('.filter-item');
      const toggle = filterItem.querySelector('.dropdown-toggle');
      const hasCheckedInputs = filterItem.querySelectorAll('input[type="checkbox"]:checked').length > 0;

      if (hasCheckedInputs) {
        toggle.classList.add('changed-color');
      } else {
        toggle.classList.remove('changed-color');
      }
    });
  });
}
