import Tagify from '@yaireo/tagify';
import cities from '@/json/cities.json';
import { lang } from '@/js/utils/locales';

export default class TagifyLocation {
  constructor({ input, form }) {
    this.dom = {
      tagifyElement: input,
      tagifyDropdownContainer: input.parentNode.querySelector('.js-city-tags__dropdown'),
      tagifyHiddenInput: undefined,
      inputsInitialValues: document.querySelectorAll('input[type="hidden"][name="City[]"]'),
      form,
    };

    this.data = {
      rawData: cities,
      tagifyData: [],
    };

    this.customEvents = {
      save: new Event('save'),
    };

    this.tagify = undefined;
    this.mount();
  }

  mount() {
    this.convertDataToTagify();
    this.createHiddenInput();
    this.setHiddenInputValue(this.dom.tagifyElement.value);
    // eslint-disable-next-line no-new
    this.tagify = new Tagify(this.dom.tagifyElement, {
      whitelist: this.data.tagifyData,
      maxTags: 5,
      enforceWhitelist: true,
      editTags: false,
      autoComplete: {
        enabled: true,
        rightKey: false,
      },
      userInput: true,
      backspace: true,
      templates: {
        tag(tagData) {
          return `<tag contenteditable="false" spellcheck="false" class="tagify__tag" data-id="${tagData.id}" data-di="${tagData.di}">
                   <x title="remove tag" class="tagify__tag__removeBtn"></x>
                   <div><span class="tagify__tag-text">${tagData.value}</span></div>
                 </tag>`;
        },
      },
      dropdown: {
        enabled: 1,
        caseSensitive: false,
        fuzzySearch: false,
        searchKeys: ['pc', 'value'],
        closeOnSelect: true,
        highlightFirst: true,
        maxItems: 10,
        placeAbove: false,
      },
      callbacks: {
        add: (e) => this.update(e.detail.tagify.value),
        remove: (e) => this.update(e.detail.tagify.value),
      },
    });
    this.addTagsByCityIds([...this.dom.inputsInitialValues].map((input) => input.value));
  }

  update(values) {
    this.setHiddenInputValue(values.map((o) => (o.id)));

    if (this.dom.form) {
      this.dom.form.dispatchEvent(this.customEvents.save);
    }
  }

  convertDataToTagify() {
    this.data.tagifyData = this.data.rawData.map((cityObj) => ({
      value: `${lang === 'en' ? cityObj.nl : cityObj[lang]} (${cityObj.pc})`,
      id: cityObj.id,
      di: cityObj.di,
      pc: cityObj.pc,
    }));
  }

  createHiddenInput() {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'Filters[City][]';
    this.dom.tagifyHiddenInput = input;
    this.dom.tagifyElement.parentNode.appendChild(input);
  }

  setHiddenInputValue(values) {
    this.dom.tagifyHiddenInput.value = values.toString();
  }

  addTagsByCityIds(cityIds) {
    this.tagify.addTags(this.data.tagifyData.filter((city) => cityIds.includes(city.id)));
  }
}
