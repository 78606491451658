import ActionHandler from '@/js/components/forms/action_handler';

export default class ProjectManagementForm extends ActionHandler {
  constructor(callback) {
    super(callback);
    this.dom = {
      form: document.getElementById('form-estate'),
      statusSwitch: document.getElementById('status-switch'),
    };

    this.data = {
      api: '',
      extraFormDataParams: {},
    };

    this.mount();
  }

  mount() {
    if (this.dom.form) {
      this.dom.form.addEventListener('submit', (e) => this.submitFormEstate(e));
    }
  }

  submitFormEstate(e) {
    e.preventDefault();
    const formData = new FormData(this.dom.form);

    if (this.dom.statusSwitch) {
      formData.append('status-switch', JSON.stringify(this.dom.statusSwitch.checked));
    }

    Object.entries(this.data.extraFormDataParams).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append('action', 'UpdateEstate');
    this.send({ url: this.dom.form.action, formData });
  }
}
