import Validation from '@/js/components/forms/validation';

class AccountActivation {
  constructor() {
    this.dom = {
      formContact: document.getElementById('form-login'),
      alertScroll: document.querySelector('.js-alert-scroll:not(.d-none)'),
    };

    this.components = {
      contactFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.formContact) {
      this.components.contactFormValidation = new Validation(this.dom.formContact);
    }

    if (this.dom.alertScroll) {
      this.dom.alertScroll.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

if (document.getElementById('page-account-activation')) {
  // eslint-disable-next-line no-new
  new AccountActivation();
}
