import { Dropdown } from 'bootstrap';

export default class FilterDropdown {
  constructor({ dropdown, form, autoSubmit = false }) {
    this.dom = {
      form,
      dropdown,
      dropdownToggle: dropdown.querySelector('[data-bs-toggle="dropdown"]'),
      saveButtons: dropdown.querySelectorAll('.js-btn-save'),
      activeCheckboxes: dropdown.querySelectorAll('input:checked'),
    };

    this.data = {
      autoSubmit,
      formData: () => this.setFormData(new FormData(this.dom.form)),
    };

    this.customEvents = {
      save: new Event('save'),
    };

    this.mount();
  }

  mount() {
    this.setDropdownStateClasses();
    this.dom.dropdown.addEventListener('show.bs.dropdown', () => this.dropdownShow());
    this.dom.dropdown.addEventListener('hide.bs.dropdown', () => this.dropdownHide());
    this.dom.saveButtons.forEach((btn) => {
      btn.addEventListener('click', (e) => this.saveButtonClick(e));
    });
  }

  unmount() {
    this.dom.dropdown.removeEventListener('show.bs.dropdown', () => this.dropdownShow());
    this.dom.dropdown.removeEventListener('hide.bs.dropdown', () => this.dropdownHide());
    this.dom.saveButtons.forEach((btn) => {
      btn.removeEventListener('click', (e) => this.saveButtonClick(e));
    });
  }

  dropdownShow() {
    this.setFormData(new FormData(this.dom.form));
  }

  dropdownHide() {
    this.save();
  }

  /**
   * set formData
   * @param formData {FormData}
   */
  setFormData(formData) {
    this.data.formData = formData;
  }

  setDropdownStateClasses() {
    if (this.dom.activeCheckboxes.length > 0) {
      this.dom.dropdown.classList.add('active');
    } else {
      this.dom.dropdown.classList.remove('active');
    }
  }

  /**
   * save button click
   * @param e {MouseEvent}
   */
  saveButtonClick(e) {
    e.preventDefault();
    this.save();
    Dropdown.getInstance(this.dom.dropdownToggle).hide();
  }

  save() {
    if (JSON.stringify([...this.data.formData.entries()]) !== JSON.stringify([...new FormData(this.dom.form).entries()])) {
      this.dom.activeCheckboxes = this.dom.dropdown.querySelectorAll('input:checked');
      this.setDropdownStateClasses();
      this.dom.form.dispatchEvent(this.customEvents.save);
    }
    this.setFormData(new FormData(this.dom.form));
  }
}
