const elements = document.querySelectorAll('.fade-in');
const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.4,
};

const callbacks = (entries, observer) => {
  entries.forEach((entry, index) => {
    if (entry.isIntersecting) {
      setTimeout(() => {
        entry.target.classList.add('active');
      }, index * 150); // Adjust the delay (in milliseconds) as per your requirement
      observer.unobserve(entry.target);
    }
  });
};

const observer = new IntersectionObserver(callbacks, options);
elements.forEach((element) => {
  observer.observe(element);
});
