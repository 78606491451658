const myInput = document.getElementById('projectSearch');
const myTable = document.getElementById('project-table');

const onInputKeyUp = () => {
  const rows = myTable.querySelectorAll('tr');
  const value = myInput.value.toLowerCase();

  rows.forEach((row) => {
    // eslint-disable-next-line no-param-reassign
    row.style.display = row.textContent.toLowerCase().includes(value) ? 'table-row' : 'none';
  });
};

const sortTableByColumn = (table, column, asc = true) => {
  const dirModifier = asc ? 1 : -1;
  const bodies = [...table.querySelectorAll('tbody')];
  const rows = [...table.querySelectorAll('tbody > tr')];

  // Get all rows of the table
  // rows = [...(tBody.querySelectorAll('tr'))];

  // Sort each row
  const sortedRows = rows.sort((a, b) => {
    const aColText = a.querySelector(`td:nth-child(${column + 1})`).textContent.trim();
    const bColText = b.querySelector(`td:nth-child(${column + 1})`).textContent.trim();

    return aColText > bColText ? 1 * dirModifier : -1 * dirModifier;
  });

  // Remove all existing TRs from the table
  bodies.forEach((tbody) => {
    // eslint-disable-next-line no-param-reassign
    tbody.innerHTML = '';
    tbody.append(...sortedRows); // @todo fix for multiple tbodies
  });

  // Remember how the column is currently sorted
  table.querySelectorAll('th').forEach((th) => th.classList.remove('asc', 'desc'));
  table.querySelector(`th:nth-child(${column + 1})`).classList.toggle('asc', asc);
  table.querySelector(`th:nth-child(${column + 1})`).classList.toggle('desc', !asc);
};

const onTableHeadSort = (headerCell) => {
  const tableElement = headerCell.closest('table');
  const headerIndex = Array.prototype.indexOf.call(headerCell.parentElement.children, headerCell);
  const currentIsAscending = headerCell.classList.contains('asc');

  sortTableByColumn(tableElement, headerIndex, !currentIsAscending);
};

// Check if the current page is the specific page where you want to apply the code
if (myInput && myTable) {
  myInput.addEventListener('keyup', () => onInputKeyUp());
}
// Check if the current page is the specific page where you want to apply the code
if (myTable) {
  document.querySelectorAll('.sortable').forEach((headerCell) => {
    headerCell.addEventListener('click', () => onTableHeadSort(headerCell));
  });
}
