import axios from 'axios';
import { rootWithLanguage } from '@/js/utils/paths';

/**
 * get regex
 * @param controller
 * @returns {Promise}
 */
export function getViews(controller) {
  return axios.get(`${rootWithLanguage}routes`, {
    params: {
      action: 'views',
      controller,
    },
  });
}

/**
 * delete photo from project
 * @param projectId {string}
 * @param photoId {string}
 * @returns {Promise<*>}
 */
export async function deleteProjectPhoto({ projectId, photoId }) {
  return axios({
    method: 'post',
    url: `${rootWithLanguage}delete-project-photo`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      ProjectID: projectId,
      ProjectPhotoID: photoId,
    },
  });
}

export async function setProjectFilesOrder({ newOrder, projectId, photoId }) {
  return axios({
    method: 'post',
    url: `${rootWithLanguage}position-project-photo`,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      newOrder,
      ProjectID: projectId,
      ProjectPhotoID: photoId,
    },
  });
}

export async function submitHomeFilters({ formData }) {
  return axios({
    method: 'post',
    url: `${rootWithLanguage}home/filter`,
    data: formData,
  });
}

export async function submitProjectFilters({ formData }) {
  const formElement = document.querySelector('.js-projects-form');
  const actionUrl = formElement.getAttribute('action');

  return axios({
    method: 'post',
    url: actionUrl,
    data: formData,
  });
}

export async function setProjectStatus({ projectId }) {
  return axios({
    method: 'post',
    url: `${rootWithLanguage}activate-project`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      ProjectID: projectId,
    },
  });
}

export async function setEstateStatus({ estateId }) {
  return axios({
    method: 'post',
    url: `${rootWithLanguage}activate-estate`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      EstateID: estateId,
    },
  });
}

export async function deactivateProject({ projectId }) {
  return axios({
    method: 'post',
    url: `${rootWithLanguage}deactivate-project`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      ProjectID: projectId,
    },
  });
}

export async function deactivateEstate({ estateId }) {
  return axios({
    method: 'post',
    url: `${rootWithLanguage}deactivate-estate`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      EstateID: estateId,
    },
  });
}

export default {
  getViews,
  deleteProjectPhoto,
  setProjectFilesOrder,
  submitProjectFilters,
  setProjectStatus,
  setEstateStatus,
  deactivateProject,
  deactivateEstate,
};
