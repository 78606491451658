import TagifyLocation from '@/js/components/tagify/tagify-location';
import ActionResponseData from '@/js/components/forms/action_response_data';
import FilterDropdown from '../filters/dropdown';

export default class FilterProjects {
  constructor({
    form, fnApi, selectSorting = undefined, autoSubmit = true,
  }) {
    this.dom = {
      form,
      selectSorting,
      dropdowns: form.querySelectorAll('.js-filter-dropdown'),
      tagifyLocation: form.querySelector('.js-city-tags'),
    };

    this.props = {
      autoSubmit,
      fnApi,
    };

    this.data = {
      formData: new FormData(),
    };

    this.components = {
      filterDropdowns: [],
      filterTagifyLocation: undefined,
    };

    this.mount();
  }

  mount() {
    this.dom.dropdowns.forEach((dropdown) => {
      this.components.filterDropdowns.push(new FilterDropdown({ form: this.dom.form, dropdown }));
    });

    if (this.dom.tagifyLocation) {
      this.components.tagifyLocation = new TagifyLocation({
        input: this.dom.tagifyLocation,
        form: this.dom.form,
      });
    }

    if (this.props.autoSubmit) {
      this.dom.form.addEventListener('save', () => this.submitForm());
    }
  }

  unmount() {
    this.components.filterDropdowns.forEach((filterDropdown) => {
      filterDropdown.unmount();
    });
    this.components.filterDropdowns = [];

    if (this.props.autoSubmit) {
      this.dom.form.removeEventListener('save', () => this.submitForm());
    }
  }

  async submitForm() {
    this.data.formData = new FormData(this.dom.form);
    const cityIds = this.data.formData.get('Filters[City][]');

    // remove City key
    this.data.formData.delete('Filters[City][]');

    // append new City key for each cityDd
    if (cityIds) {
      cityIds.split(',').forEach((cityId) => this.data.formData.append('Filters[City][]', cityId));
    }

    // append sorting
    if (this.dom.selectSorting) {
      this.data.formData.append('Filters[Sorting]', this.dom.selectSorting.value);
    }

    try {
      const resp = await this.props.fnApi({ formData: this.data.formData });
      (new ActionResponseData(resp.data)).process();
    } catch (e) {
      throw new Error(e);
    }
  }
}
