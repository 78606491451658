import ActionHandler from '@/js/components/forms/action_handler';
// eslint-disable-next-line import/no-extraneous-dependencies,no-unused-vars
import Dropzone from 'dropzone';

export default class ProjectManagementForm extends ActionHandler {
  constructor(callback) {
    super(callback);
    this.dom = {
      form: document.getElementById('form-project'),
      statusSwitch: document.getElementById('status-switch'),
    };

    this.data = {
      api: '',
      extraFormDataParams: {},
    };

    // this.detailInstance = detailInstance;

    this.mount();
  }

  mount() {
    if (this.dom.form) {
      this.dom.form.addEventListener('submit', (e) => this.submitFormProject(e));
    }
  }

  submitFormProject(e) {
    e.preventDefault();
    const formData = new FormData(this.dom.form);
    if (this.dom.statusSwitch) {
      formData.append('status-switch', JSON.stringify(this.dom.statusSwitch.checked));
    }

    Object.entries(this.data.extraFormDataParams).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // Get the current URL
    const currentUrl = window.location.href;
    const lastSlashIndex = currentUrl.lastIndexOf('/');
    const baseUrl = currentUrl.substring(0, lastSlashIndex);

    this.send({ url: baseUrl, formData });
  }
}
