// components
import EstateManagementForm from '@/js/components/forms/estate-management-form';
import { deactivateEstate, setEstateStatus } from '@/js/api/api';

// click on button registreren
// formData + this.data.mapCoordinates appenden aan de formData + statusSwitch

class EstateManagementDetail {
  constructor() {
    this.dom = {
      formEstate: document.getElementById('form-estate'),
      pdfUpload: document.getElementById('estate-pdf'),
      pdfUploadReceiver: document.querySelector('.upload-receiver'),
      statusSwitch: document.getElementById('status-switch'),
      alertContainer: document.getElementById('alert-container'),
    };

    this.data = {
      estateId: document.querySelector('[name="EstateID"]').value,
    };

    this.components = {
      estateManagementForm: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.formEstate) {
      this.components.estateManagementForm = new EstateManagementForm((response) => this.handleResponseMessage(response));
    }
    if ((this.dom.pdfUpload && this.dom.pdfUploadReceiver)) {
      this.pdfUpload();
    }
    if (this.dom.statusSwitch) {
      this.dom.statusSwitch.addEventListener('change', () => this.toggleEstateStatus(this.dom.statusSwitch));
    }
  }

  pdfUpload() {
    this.dom.pdfUpload.addEventListener('change', () => {
      const fileName = this.dom.pdfUpload.value.split('\\').pop(); // Extract the file name from the file path
      this.dom.pdfUploadReceiver.textContent = fileName || ''; // Set the text of the label to the file name
    });
  }

  toggleEstateStatus(statusSwitch) {
    const { estateId } = this.data;
    const newStatus = statusSwitch.checked;

    const statusChangeFunction = newStatus ? setEstateStatus : deactivateEstate;

    statusChangeFunction({ estateId })
      .then((response) => {
        if (response.data.status === 'error') {
          this.displayAlert('alert-warning', response.data.message);
          // eslint-disable-next-line no-param-reassign
          statusSwitch.checked = !newStatus;
        } else if (response.data.status === 'success') {
          // eslint-disable-next-line no-param-reassign
          statusSwitch.checked = newStatus;
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-param-reassign
        if (error.response) {
          // Handle the response error if it exists
          this.displayAlert('alert-warning', 'API request failed. Switch state reverted.');
        }
      });
  }

  handleResponseMessage(response) {
    const { message, errors } = response.data;

    const addErrorDisplayClass = (fieldNames) => {
      if (fieldNames) {
        fieldNames.forEach((fieldName) => {
          const inputField = document.querySelector(`[name="${fieldName}"]`);
          if (inputField) {
            inputField.classList.add('error-display');

            // Replace the placeholder with the error message
            const errorMessage = errors[fieldName];
            if (errorMessage) {
              inputField.setAttribute('placeholder', errorMessage);
            }
          }
        });
      }
    };

    if (message.type === 'success') {
      this.displayAlert('alert-success', message.text); // Display a success alert
    } else if (message.type === 'warning') {
      this.displayAlert('alert-warning', message.text); // Display a warning alert
      this.dom.statusSwitch.checked = false;
      addErrorDisplayClass(Object.keys(errors));
    } else {
      this.displayAlert('alert-danger', `Unknown message type: ${message.type}`); // Display a generic error alert
      this.dom.statusSwitch.checked = false;
      addErrorDisplayClass(Object.keys(errors));
    }
  }

  displayAlert(alertClass, message) {
    const alertElement = document.createElement('div');
    alertElement.classList.add('alert', alertClass, 'alert-dismissible', 'fade', 'show');
    alertElement.innerHTML = `${message}`;
    this.dom.alertContainer.appendChild(alertElement);

    // Automatically close the alert after a few seconds
    setTimeout(() => {
      alertElement.classList.remove('show');
      alertElement.classList.add('hide');
      setTimeout(() => {
        this.dom.alertContainer.removeChild(alertElement);
      }, 500);
    }, 5000);
  }
}

if (document.getElementById('page-estate-management-detail')) {
  // eslint-disable-next-line no-new
  new EstateManagementDetail();
}
